<template>
    <!-- Horizontal Scroll Area -->
    <div 
        class="scroll-area glass-effect"
        :class="isScrollAreaVisible ? 'visible' : 'not-visible'" 
        :style="{ bottom: isScrollAreaVisible ? '0' : '-150px' }"
        >
        <ul>
            <li v-for="(item, index) in list" :key="item">
                <img 
                    :key="index" 
                    :src="item.preview"
                    alt="Body Image" 
                    class="body-image"
                    @click="bodyClicked(list[index])"/>
            </li>
        </ul>
    </div>

    <!-- Button to Reveal Scroll Area -->
    <div class="body-btn-wrapper">
      <img :class="isScrollAreaVisible ? 'check' : ''" @click="toggleScrollArea" :src="isScrollAreaVisible ? '/check.png' : '/body_icon.png'" alt="color"/>
    </div>

    <div v-if="isScrollAreaVisible" class="body-btn-wrapper back">
      <img @click="toggleScrollArea" src="/back__.png" alt="color" width="24"
           height="24"/>
    </div>
    
</template>

<script>
export default {
  name: 'BodyList',
  props: {
    list: {
      type: Array,
      required: true,
    },
    bodyClicked: {
        type: Function,
        required: false
    },
    panelVisible: {
      type: Function,
      required: false
    },
    toggle: Boolean
  },
  components: {
  },
  data() {
    return {
      isScrollAreaVisible: false,
      body_toggle: false
    };
  },
  watch: {
    toggle(value) {
      this.isScrollAreaVisible = value;
    }
  },
  methods: {
    toggleScrollArea() {
      this.isScrollAreaVisible = !this.isScrollAreaVisible;
      this.panelVisible(this.isScrollAreaVisible);
    }
  },
};
</script>
<style scoped>

.scroll-area {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 150px;
  overflow-x: scroll;
  overflow-y: hidden;
  transition: bottom 0.3s;
  border-top: 1px solid #c2c2c2;

  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.scroll-area ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.scroll-area ul li {
  flex: 0 0 auto;
  padding: 10px 20px;
  border-right: 1px solid #000;
}

.body-image {
  width: 80px; /* Adjust as needed */
  height: 80px;
  overflow: hidden;
  margin-left: 10px; /* Adjust as needed */
}


.body-btn-wrapper {
  position: absolute;
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  top: 10px;
  left: 90px;
  background-color: rgba(255, 255, 255, 1); /* Semi-transparent white */
  border-radius: 25px; /* Circular shape */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  transition: width 0.3s ease, height 0.3s ease;
}
.body .body-btn-wrapper {
  left: 10px;
  top: 50px;
}
.body .body-btn-wrapper.back {
  left: 10px;
  top: 10px;
}
.body-btn-wrapper img {
  width: 24px; /* Adjust as needed */
  height: 24px; /* Adjust as needed */
  padding: 3px;
}
.body .body-btn-wrapper .check {
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
}
  .body-btn-wrapper.visible {
    left: 10px;
  }

</style>
