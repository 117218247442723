<template>
  <div class="viewer-container">
    <div id="container">
      <div class="logo"><img src="/logo-horizontal.png"/></div>
      <div class="desc">{{
          avatars.length ? 'Lütfen düzenlemek istediğiniz avatarı seçin.' : 'Merhaba. Hemen başlamak için bir avatar oluştur.'
        }}
      </div>
      <div class="center-vertical">
        <div class="avatar-wrapper">
          <div class="avatar" v-for="(avatar, index) in avatars" :key="index">
            <img v-if="avatar.render_url" :src="avatar.render_url" :alt="`avatar${index}`"/>
            <div v-else class="placeholder">
              Yükleniyor
            </div>
            <div class="overlay">
              <div class="button" @click="avatarSelected(avatar)">Düzenle</div>
              <div class="button" @click="deleteAvatar(avatar)">Sil</div>
            </div>
          </div>
        </div>
        <div class="create-button" @click="createAvatar">{{buttonText}}</div>

        <div class="or">
          ya da
        </div>
        <div class="view-button" @click="baseView">Manken Üzerinde İncele</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AvatarList',
  props: {
    avatars: {
      type: Array
    },
    deleteAvatar: {
      type: Function
    },
    avatarSelected: {
      type: Function
    },
    back: {
      type: Function
    },
    baseView: {
      type: Function
    }
  },
  data() {
    return {
      buttonText: 'Avatar Oluştur'
    }
  },
  methods: {
    createAvatar() {
      if(this.avatars.length < 2) {
        this.back('init')
      } else {
        this.buttonText = 'Yalnızca 2 adet avatar oluşturabilirsiniz.'
      }
    }
  }
}
</script>
<style scoped>
#container {
  display: flex;
  flex-direction: column;
}
.center-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.logo {
  width: 150px;
  margin: 0 auto;
  padding: 10px 0 0 0;
}

.avatar-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-height: 350px;
  overflow: auto;
}

.avatar {
  width: calc(30% - 10px); /* Adjust width for 2 items per row, accounting for the gap */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  backdrop-filter: blur(10px); /* This provides the blurred background effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: all 1s ease;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s ease;
  opacity: 0;
  flex-direction: column;
  gap: 20px;
}

.placeholder {
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;
  width: 100%; /* Placeholder size */
  height: 220px;
  font-size: 14px;
  background-color: #e0e0e0; /* Placeholder color */
}

.avatar:hover .overlay {
  opacity: 1;
}

.overlay .button:hover {
  color: white;
}
.overlay .button {
  color: #cccccc;
}

.avatar:hover {
  transform: scale(0.99);
}

.avatar img {
  background: #f7f7f7;
}

img {
  max-width: 100%;
  height: auto;
}

.title {
  font-size: 22px;
  padding: 0px 0 10px 0;
  color: white;
}

.desc {
  font-size: 16px;
  padding: 0px 0 10px 0;
  color: white;
}

.bold {
  font-weight: 700;
}

#container {
  text-align: center;
}

.or {
  color: #f7f7f7;
  padding: 20px 0;
}

.create-button {
  background: transparent;
  border: 1px solid #f7f7f7;
  color: #f7f7f7;
  padding: 10px 20px;
  max-width: 200px;
  border-radius: 10px;
  margin: 20px auto 10px auto;
  cursor: pointer;
  transition: all .5s;
}

.view-button {
  color: #ccc;
  text-decoration: underline;
  font-size: 15px;
  cursor: pointer;
}

.create-button:hover {
  background: #f7f7f7;
  color: #333;
}
</style>