<template>
  <img class="matchy-logo-2" :class="position" src="/logo-horizontal.png" alt="logo" />
</template>
<script>
export default {
  name: 'MatchyLogo2',
  props: {
    position: String
  }
}
</script>
<style>
.matchy-logo-2 {
  height: 20px;
  position: absolute;
  bottom: 160px;
  right: 5px;
}
</style>
<script setup>
</script>
