<template>
  <div class="confirm-container">
    <div class="confirm-icon" @click="goBack">
      <!-- Replace with your settings icon -->
      <span class="title">Devam et</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ConfirmButton',
  props: {
    goBack: Function
  }
}

</script>

<style scoped>

.confirm-container {
  position: absolute;
  width: auto; /* Adjust size as needed */
  height: auto; /* Adjust size as needed */
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 1); /* Semi-transparent white */
  border-radius: 5px; /* Circular shape */
  padding: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  transition: width 0.3s ease, height 0.3s ease;
}
.confirm-icon {
  position: relative;
}
.confirm-icon img, .settings-icon {
  width: 24px; /* Adjust as needed */
  height: 24px; /* Adjust as needed */
  padding: 4px 3px 3px 0;
  transform: rotate(90deg);
}
</style>