import { createStore } from 'vuex';

export default createStore({
  state: {
    userId: null,
    avaturnUrl: null,
    sessionId: null,
    userAvatarUrl: null,
    userBodyId: null,
    avatarGender: null
  },
  mutations: {

    setSessionId(state, value) {
      state.sessionId = value;
      localStorage.setItem('sessionId', value);
    },
    setAvaturnUrl(state, value) {
      state.avaturnUrl = value;
      localStorage.setItem('avaturnUrl', value);
    },
    setUserId(state, value) {
      state.userId = value;
      localStorage.setItem('userId', value);
    },
    setUserAvatarUrl(state, userAvatarUrl) {
      state.userAvatarUrl = userAvatarUrl;
      localStorage.setItem('userAvatarUrl', userAvatarUrl);
    },
    setUserBodyId(state, userBodyId) {
      state.userBodyId = userBodyId;
      localStorage.setItem('userBodyId', userBodyId);
    },
    setAvatarGender(state, avatarGender) {
      state.avatarGender = avatarGender;
      localStorage.setItem('avatarGender', avatarGender);
    }
  },
  actions: {
    setUserId({ commit }, value) {
        commit('setUserId', value);
    },
    setAvaturnUrl({ commit }, value) {
        commit('setAvaturnUrl', value);
    },
    setSessionId({ commit }, value) {
        commit('setSessionId', value);
    },
    setUserAvatarUrl({ commit }, userAvatarUrl) {
        commit('setUserAvatarUrl', userAvatarUrl);
    },
    setUserBodyId({ commit }, userBodyId) {
        commit('setUserBodyId', userBodyId);
    },
    setAvatarGender({ commit }, avatarGender) {
      commit('setAvatarGender', avatarGender)
    }
  }
});
