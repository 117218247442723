<template>
  <div class="panel-container" :class="{ 'is-open': isOpen }">
    <div class="panel-header" @click="togglePanel">
      <MatchyLogo class="reverse" />
      <div class="arrow glass-effect"><img src="/arrow_down.png" alt="Toggle"></div>
      <div class="product-name glass-effect">{{ productName }}</div>
    </div>
    <div class="scroll-area glass-effect">
      <div class="image-container" v-for="(key, index) in Object.keys(recommended)" :key="index">
        <HorizontalProductItem ref="horizontalList" :product="recommended[key]" :product-selected="productSelected"></HorizontalProductItem>
      </div>
    </div>
  </div>
</template>
<script>
import HorizontalProductItem from "./HorizontalProductItem.vue";
import MatchyLogo from "./MatchyLogo.vue";
export default {
  props: {
    productName: String,
    recommended: Array,
    productSelected: Function,
    toggled: Function
  },
  watch: {
    recommended: {
      handler() {
        console.log('pr list change', this.recommended)
      },
      deep: true
    },
    isOpen() {
      this.toggled(this.isOpen)
    }
  },
  components: {
    MatchyLogo,
    HorizontalProductItem
  },
  data() {
    return {
      isOpen: false
    };
  },
  mounted() {
    console.log('bottom list', this.recommended);
  },
  methods: {
    togglePanel() {
      this.isOpen = !this.isOpen;
    },
    closePanel() {
      this.isOpen = false;
    },
    openPanel() {
      this.isOpen = true;
    }
  }
};
</script>
<style scoped>
.reverse {
  filter: invert(100%) contrast(200%) saturate(1000%);

}
.panel-container {
  position: absolute;
  transition: transform 0.3s ease-in-out;
  transform: translateY(70%); /* Panel visible */
  bottom: 0;
}

.panel-container.is-open {
  transform: translateY(0%); /* Panel hidden */
}

.panel-header {
  cursor: pointer;
  /* Styles for the header */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrow {
  /* Style the arrow */
  width: 80px;
  height: 30px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.arrow img {
  width: 32px;
  height: 32px;
  transform: rotate(180deg);
  transition: transform 0.25s ease-in-out; /* Adjust time and easing function as needed */
}
.panel-container.is-open .arrow img {
  transform: rotate(0deg);
}
.product-name {
  /* Style the product name */
  width: 100%;
  padding: 1em 0;
  text-align: center;
  font-size: 14px;
}

.scroll-area {
  display: flex;
  overflow-x: auto;
  padding-left: 10px;
  /* Styles for the scrollable area */
}

.image-container {
  flex: 0 0 auto; /* Adjust size as needed */
  /* Style each image container */
}

.image-container img {
  width: 100px; /* Adjust as needed */
  /* Additional image styles */
}
</style>
