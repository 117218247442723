<template>
  <img class="matchy-logo-3" :class="position" src="/logo-horizontal.png" alt="logo" />
</template>
<script>
export default {
  name: 'MatchyLogo2',
  props: {
    position: String
  }
}
</script>
<style>
.matchy-logo-3 {
  height: 70px;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>
<script setup>
</script>
