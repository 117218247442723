<template>
    <div class="final-wrapper">
        <div class="camera-container" v-if="capturedImages.length < 3">
            <div class="message">{{ faceTexts[capturedImages.length] }}</div>
            <div class="camera">
                <div class="preview" :class="[capturedImages.length === 2 ? 'reverse' : '']">
                    <img :src="faceSides[capturedImages.length]" />
                </div>
                <div class="capture-effect" v-show="showCaptureEffect" @animationend="showCaptureEffect = false"></div>
                <video ref="videoElement" class="camera-feed" autoplay playsinline></video>
                <canvas ref="canvas" style="display: none;"></canvas>

            </div>
            <div class="camera-controls">
                <div class="btn-icon" @click="switchCamera">
                    <img src="/form/switch-cam.png" />
                </div>
                <div class="btn-icon cam" @click="captureImage">
                    <div class="innercircle"></div>
                </div>
                <div class="btn-icon" @click="showInfo">
                    <img src="/form/info.png" />
                </div>
            </div>
        </div>
        <div class="upload-container" v-else>
            <div class="title">Nasıl görünüyor?</div>
            <div class="image-container">
                <div class="col-left">
                    <div class="image-taken" :style="`background-image: url(${capturedImages[0].url})`">
                        <div class="preview">
                            <img :src="faceSides[0]" />
                        </div>
                    </div>
                </div>
                <div class="col-right">
                    <div class="image-taken" :style="`background-image: url(${capturedImages[1].url})`">
                        <div class="preview">
                            <img :src="faceSides[1]" />
                        </div>
                    </div>
                    <div class="image-taken" :style="`background-image: url(${capturedImages[2].url})`">
                        <div class="preview reverse">
                            <img :src="faceSides[2]" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="info">
                <div class="info-icon">
                    <img src="/form/info.png" />
                </div>
                <div class="info-text">
                    Lütfen fotoğraflarınızın kılavuzumuza uygun olduğundan emin olun.
                </div>
            </div>
            <div class="buttons">
                <div class="retake" @click="retake">
                    <img src="/refresh_white.png" />
                    <span>Yeniden Çek</span>
                </div>
                <div class="upload" @click="createNewAvatar">Yükle</div>
            </div>
            <div class="uploading-overlay" v-if="uploading">
                <div class="uploading-wrapper">
                    <div class="loader" style="background-image: url('/form/border.png');">
                        <div class="rotator rotating-element" style="background-image: url('/form/loading.png');"></div>
                        <div class="face" style="background-image: url('/form/face.png');"></div>
                        <div class="percentage">{{uploadProgress}} %</div>
                    </div>
                    <div class="title">Yükleniyor</div>
                    <div class="desc">Fotoğraflarınız yükleniyor</div>

                    <div class="logo"><img src="/logo-horizontal.png" /></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        info: Function,
        uid: String,
        completed: Function,
        gender: String
    },
    data() {
        return {
            token: 'PsP6tfiW-Au4JbD8QE9RVb8I6UDSWPpgP2JQ0xx07xs-mzzK2XDbv5EltHJyalPp1qNM_5vXNJgfcLqJdBMDUg',
            stream: null,
            currentDeviceId: null,
            allVideoInputs: [],
            capturedImages: [],
            faceSideIndex: 0,
            uploadProgress: 0,
            avatarId: null,
            uploading: false,
            showCaptureEffect: false,
            faceTexts: ['Lütfen doğrudan kameraya bakın', 'Kafanızı sağa çevirin.', 'Kafanızı sola çevirin.'],
            faceSides: ['/form/face-front.png', '/form/face-left.png', '/form/face-right.png']
        };
    },
    mounted() {
        this.getCameraFeed();
    },
    methods: {
        retake() {
            this.capturedImages = []
        },
        async getCameraFeed() {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                this.allVideoInputs = devices.filter(device => device.kind === 'videoinput');

                if (this.allVideoInputs.length > 0) {
                    this.currentDeviceId = this.allVideoInputs[0].deviceId;
                    this.stream = await navigator.mediaDevices.getUserMedia({
                        video: { deviceId: this.currentDeviceId }
                    });
                    this.$refs.videoElement.srcObject = this.stream;
                } else {
                    alert('No camera found.');
                }
            } catch (err) {
                alert('Camera access denied.');
                console.error(err);
            }
        },

        uploadFile(blob, index) {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append('image', blob, `image${index}.png`);

                const xhr = new XMLHttpRequest();
                xhr.open('POST', 'https://fashionmakerapi.arftower.com/api/store/plugin', true);

                xhr.upload.onprogress = (event) => {
                    if (event.lengthComputable) {
                        this.uploadProgress = Math.round((event.loaded * 100) / event.total);
                    }
                };

                xhr.onload = () => {
                    this.uploadProgress = 0; // Reset progress
                    if (xhr.status >= 200 && xhr.status < 300) {
                        resolve(xhr.responseText);
                    } else {
                        reject(new Error('Upload failed: ' + xhr.statusText));
                    }
                };

                xhr.onerror = () => reject(new Error('Upload failed: ' + xhr.statusText));

                xhr.send(formData);
            });
        },
        async uploadAllFiles() {
            this.uploading = true;
            for (let i = 0; i < this.capturedImages.length; i++) {
                try {
                    const response = await this.uploadFile(this.capturedImages[i], i);
                    console.log(`Upload successful for image ${i}:`, response);
                    // Optional: Handle the response, e.g., showing a success message
                } catch (error) {
                    console.error(`Upload failed for image ${i}:`, error);
                    // Optional: Handle the upload error, e.g., showing an error message
                    break; // Stop uploading the remaining files on failure
                }
            }
            this.uploading = false;
        },
        async createNewAvatar() {

            this.uploading = true;
            try {
                const response = await fetch('https://api.avaturn.me/api/v1/avatars/new', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        user_id: this.uid
                    })
                });

                if (!response.ok) {
                    this.isLoading = false;
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                console.log('new avatar', data.upload_url);
                this.avatarId = data.avatar_id;
                this.uploadAvatarFiles(data.upload_url);
            } catch (error) {
                console.error('Error making the API call:', error);

                this.uploading = false;
            }
        },
        uploadAvatarFiles(uploadUrl) {
            const self = this;
            const formData = new FormData();
            formData.append('body-type', this.gender);
            formData.append('telephoto', 'false');
            formData.append('image-frontal', this.capturedImages[0].blob, 'image-frontal.jpg');
            formData.append('image-side-1', this.capturedImages[1].blob, 'image-side-1.jpg');
            formData.append('image-side-2', this.capturedImages[2].blob, 'image-side-2.jpg');
            const xhr = new XMLHttpRequest();
            xhr.open('POST', uploadUrl);

            xhr.upload.onprogress = function (event) {
                if (event.lengthComputable) {
                    self.uploadProgress = parseInt((event.loaded / event.total) * 100);
                    console.log(`Upload progress: ${self.uploadProgress}%`);
                }
            };

            xhr.onload = function () {
                if (xhr.status === 200) {
                    self.uploadProgress = 0;
                    console.log('Upload successful:', xhr.responseText);
                    self.uploading = false;
                    self.completed(self.avatarId);
                } else {
                    console.error('Upload failed:', xhr.statusText);
                    self.uploading = false;
                }
            };

            xhr.onerror = function () {
                console.error('Network error');
                self.uploading = false;
            };

            xhr.send(formData);

        },
        captureImage() {
            this.showCaptureEffect = true;
            const videoElement = this.$refs.videoElement;
            const canvas = this.$refs.canvas;
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;

            const context = canvas.getContext('2d');
            context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

            // Convert the canvas content to a blob and store it in the array
            canvas.toBlob(blob => {
                // Add the blob to the capturedImages array
                this.capturedImages.push({
                    url: URL.createObjectURL(blob),
                    blob: blob
                });

                // Optional: Log the array length to see how many images have been captured
                console.log('Captured images count:', this.capturedImages);
            }, 'image/png');
        },

        switchCamera() {
            const currentIndex = this.allVideoInputs.findIndex(device => device.deviceId === this.currentDeviceId);
            if (currentIndex !== -1 && this.allVideoInputs.length > 1) {
                const nextIndex = (currentIndex + 1) % this.allVideoInputs.length;
                this.currentDeviceId = this.allVideoInputs[nextIndex].deviceId;
                this.getCameraFeed(); // Refresh the camera feed with the new device
            }
        },
        showInfo() {
            // Implementation for showing info or additional actions
            this.info();
        },
    },
};
</script>

<style scoped>
.percentage {
    position: absolute;
    bottom: -20px;
    z-index: 2;
    color: white;
    left: 50%;
    transform: translateX(-50%);
    font-size: 25px;
}
.rotator,
.face {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

@keyframes rotateAnimation {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.rotating-element {
    animation: rotateAnimation 2s linear infinite;
}

.face {
    width: 50px;
    height: 50px;
}

.rotator {
    width: 110px;
    height: 110px;
}

.loader {
    width: 250px;
    height: 250px;
    background-size: contain;
    background-position: center;
    position: relative;
}

.uploading-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.uploading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20%;
    background: #333;
    z-index: 4;
}

.upload-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.retake {
    color: white;
    max-width: 50%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.retake img {
    width: 12px;
    height: 12px;
}

.upload {
    color: #333;
    background: white;
    border-radius: 32px;
    width: 50%;
    margin: 0 auto;
    padding: 10px 20px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.buttons {
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 20px;
}

.image-container {
    display: flex;
    height: -moz-fit-content;
    height: fit-content;
    height: 370px;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    width: calc(100% - 80px);
}

.title {
    text-align: center;
    font-size: 30px;
    color: white;
    padding: 20px 0;
}

.desc {
    font-size: 14px;
    color: white;
}

.col-left {
    display: flex;
    margin-right: 16px;
    width: 65%;
}

.col-right {
    display: flex;
    flex-flow: column;
    gap: 16px;
    width: 35%;
}

.info {
    display: flex;
    gap: 10px;
    max-width: 40%;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
}

.info-icon {
    padding-right: 10px;
    border-right: 1px solid #ccc;
}

.info-icon img {
    width: 100%;
}

.info-text {
    color: white;
}

.image-taken {

    align-items: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    display: flex;
    height: 100%;
    justify-content: center;
    -o-object-fit: cover;
    object-fit: cover;
    position: relative;
    width: 100%;
}

.preview {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 15px;
    overflow: hidden;
    max-width: 50px;
    z-index: 2;
}

.preview.reverse {
    transform: rotateY(180deg);
}

.preview img {
    width: 100%;
}

.image-taken .preview {
    max-width: 30px;
    border-radius: 4px;
}

.message {
    position: absolute;
    top: 10px;
    left: 20px;
    right: 20px;
    color: white;
    font-size: 14px;
    text-align: center;
}

.camera-container,
.upload-container,
.final-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* Adjust based on your needs */
}

.camera {
    flex: 1;
    margin: 15% 10% 10px 10%;
    border-radius: 25px;
    overflow: hidden;
    max-height: 500px;
    position: relative;
}

.camera-feed {
    object-fit: cover;
    height: 100%;
    width: 100%;
    transform: scaleX(1);

    /* Adjust video feed to cover the area */
}

.camera-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    /* Space between buttons */
}

.capture-effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0;
    animation: flash 0.5s forwards;
    z-index: 3;
}

@keyframes flash {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.btn-icon img {
    max-width: 100%;
}

.btn-icon {
    width: 32px;
    height: 32px;
    margin: 20px;
}

.logo img {
    max-width: 90px;
}

.logo {
    position: absolute;
    bottom: 10px;
}

.btn-icon.cam {

    width: 80px;
    height: 80px;
    border: 4px solid white;
    border-radius: 50%;
}

.innercircle {
    margin: 5px;
    background: white;
    border-radius: 50%;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
}

@media only screen and (max-width: 450px) {
    .info {
        max-width: 70%;
    }

    .info-icon {
        width: 50px;
    }

    .buttons>div {
        width: 70%;
        max-width: 70%;
    }
}
</style>
