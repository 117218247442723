<template>
  <div class="settings-container"
       @mouseenter="openMenu"
       @mouseleave="closeMenuIfNotActive">
    <div class="settings-icon">
      <!-- Replace with your settings icon -->
      <img src="/settings.png" alt="Settings">
    </div>
    <div class="buttons" v-show="showButtons"
         @mouseenter="menuActive = true"
         @mouseleave="menuActive = false">
      <div class="button-item" @click="createAvatar('list')">
        <button class="icon-button"><img src="/body_icon.png" alt="Button 1"></button>
        <span>Kendi Avatarını Oluştur</span>
      </div>
      <div class="button-item" @click="reset">
        <button class="icon-button"><img src="/refresh.png" alt="Button 2"></button>
        <span>Sıfırla</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    createAvatar: Function
  },
  data() {
    return {
      showButtons: false,
      menuActive: false,

    };
  },
  methods: {
    openMenu() {
      this.showButtons = true;
    },
    reset () {
      localStorage.clear();
    },
    closeMenuIfNotActive() {
      setTimeout(() => {
        if (!this.menuActive) {
          this.showButtons = false;
        }
      }, 1000);
    },
  },
};
</script>
<style scoped>
.menu-container {

}
.settings-container {
  position: absolute;
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  top: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 1); /* Semi-transparent white */
  border-radius: 25px; /* Circular shape */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  transition: width 0.3s ease, height 0.3s ease;
}

.settings-icon img, .settings-icon {
  width: 18px; /* Adjust as needed */
  height: 18px; /* Adjust as needed */
}

.buttons {
  position: absolute;
  top: 40px;
  left: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 220px;
  height: auto;
  background: white;
  border-radius: 10px;
  padding: 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}

.icon-button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.icon-button img, .icon-button {
  width: 18px; /* Adjust as needed */
  height: 18px; /* Adjust as needed */
}
.button-item {
  display: flex;
  justify-content: center;
  padding: 5px;
}
.button-item span {
  padding: 0 7.5px;
  font-size: 14px;
  font-weight: 300;
}
</style>
