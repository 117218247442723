<template>
  <div class="back-container">
    <div class="back-icon" @click="goBack">
      <!-- Replace with your settings icon -->
      <img src="/back__.png" alt="Settings">
    </div>
  </div>
</template>
<script>
export default {
  name: 'BackButton',
  props: {
    goBack: Function
  }
}

</script>

<style scoped>

.back-container {
  position: absolute;
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  top: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 1); /* Semi-transparent white */
  border-radius: 25px; /* Circular shape */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  transition: width 0.3s ease, height 0.3s ease;
  z-index: 999;
}
.back-icon {
  position: relative;
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
}
.back-icon img, .settings-icon {
  width: 24px; /* Adjust as needed */
  height: 24px; /* Adjust as needed */
  padding: 3px;
}
</style>