<template>
  <!-- Horizontal Scroll Area -->

  <MatchyLogo2 v-if="isScrollAreaVisible"/>
  <div
      class="scroll-area glass-effect"
      :style="{ bottom: isScrollAreaVisible ? '0' : '-150px' }"
  >

    <ul>
      <li v-for="(item, index) in list" :key="item">
        <img
            :key="index"
            :src="item.preview"
            alt="Hair Image"
            class="hair-image"
            @click="hairClicked(list[index])"/>
      </li>
    </ul>
  </div>
  <!-- Button to Reveal Scroll Area -->
  <div class="btn-wrapper" v-if="!isScrollAreaVisible">
    <img @click="toggleScrollArea" src="/cut.png" alt="hair" width="24" height="24"/>
  </div>
  <div v-if="isScrollAreaVisible" class="btn-wrapper cut">
    <img @click="colorToggle = !colorToggle" :src="colorToggle ? '/check.png' : '/brush.png'" alt="color" width="24"
         height="24"/>
    <color-picker v-if="colorToggle" alpha-channel="hide" :color="color" @color-change="updateColor"/>
  </div>
  <div v-if="isScrollAreaVisible" class="btn-wrapper back">
    <img @click="toggleScrollArea" src="/back__.png" alt="color" width="24"
         height="24"/>
  </div>
</template>

<script>
import {ColorPicker} from 'vue-accessible-color-picker'
import MatchyLogo2 from "@/components/MatchyLogo2.vue";

export default {
  name: 'AssetList',
  props: {
    list: {
      type: Array,
      required: true,
    },
    hairClicked: {
      type: Function,
      required: false
    },
    hairColorChanged: {
      type: Function,
      required: false
    },
    panelVisible: {
      type: Function,
      required: false
    },
    toggle: Boolean
  },
  components: {
    MatchyLogo2,
    ColorPicker
  },
  data() {
    return {
      isScrollAreaVisible: false,
      color: '#000000',
      colorToggle: false
    };
  },
  watch: {
    toggle(value) {
      this.isScrollAreaVisible = value;
    },
    colorToggle() {
      if (!this.colorToggle) {
        //this.hairColorChanged(this.color)
      }
    }
  },
  methods: {
    toggleScrollArea() {
      this.isScrollAreaVisible = !this.isScrollAreaVisible;
      this.panelVisible(this.isScrollAreaVisible);
    },
    updateColor(eventData) {
      this.color = eventData.colors.hex.substring(0, 7)
      console.log('color', this.color)
    }
  },
};
</script>
<style scoped>

.scroll-area {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 150px;
  overflow-x: scroll;
  overflow-y: hidden;
  transition: bottom 0.3s;
  border-top: 1px solid #000;
  /* Adding a shadow */
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.scroll-area ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.scroll-area ul li {
  flex: 0 0 auto;
  padding: 10px 20px;
  border-right: 1px solid #000;
}

.hair-image {
  width: 80px; /* Adjust as needed */
  height: 80px;
  overflow: hidden;
  margin-left: 10px; /* Adjust as needed */
}

.btn-wrapper {
  position: absolute;
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  top: 10px;
  left: 50px;
  background-color: rgba(255, 255, 255, 1); /* Semi-transparent white */
  border-radius: 25px; /* Circular shape */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  transition: width 0.3s ease, height 0.3s ease;
}
.asset .btn-wrapper.cut {
  top: 50px;
  left: 10px;
}
.asset .btn-wrapper.back {
  top: 10px;
  left: 10px;
}
.btn-wrapper img {
  width: 22px; /* Adjust as needed */
  height: 22px; /* Adjust as needed */
  padding: 3px;
}
.btn-wrapper-color {
  width: 24px;
  height: 24px;
  z-index: 99999;
  position: absolute;
  left: 50px;
  top: 10px;
  background: white;
  border-radius: 50%;
  padding: 5px;
}

.vacp-color-picker {
  top: 40px;
  left: 0;
  position: absolute;
  width: 250px;
  border-radius: 10px;
  opacity: 0.75;
}
</style>
