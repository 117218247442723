<template>
  <img class="matchy-logo" :class="position" src="/logo-horizontal.png" alt="logo" />
</template>
<script>
export default {
  name: 'MatchyLogo',
  props: {
    position: String
  }
}
</script>
<style>
.matchy-logo {
  height: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.bottom {

}
</style>
<script setup>
</script>
