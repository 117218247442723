<template>
  <div v-if="product" class="product" @click="productSelected(product)">
    <div class="img-wrapper" :style="`background-image: url(${product.images[0]})`">
      <div class="spinner-wrapper" v-if="product.loaded || product.loading">
        <div class="spinner" v-if="product.loading"></div>
        <div class="loaded" v-if="product.loaded">
          <img src="/close.png" alt="shuffle" width="24" height="24" />
          <span>Çıkar</span>
        </div>
      </div>
    </div>
    <p class="name">{{ product.name }}</p>
  </div>
</template>
<script>
export default {
  name: 'HorizontalProductItem',
  props: {
    product: Object,
    productSelected: Function
  },
  data() {
    return {
      currentProduct: null
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style>
.product {
  flex: 0 0 auto;
  position: relative;
}

.product .img-wrapper {
  width: 100px;
  height: 140px;
  margin-right: 10px;
  border-radius: 10px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}

.product img {
  max-width: 100%;
  display: block
}

.product .name {
  font-size: 12px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.spinner-wrapper {
  border-radius: 10px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 140px;
  background-color: rgba(255, 255, 255, 0.5);
}

.spinner {
  /* Styles for your spinner, for example: */
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  z-index: 99;
  position: absolute;
  left: 30px;
  top: 50px;
}

.loaded {
  z-index: 99;
  position: absolute;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

</style>
